.pagination-container {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-container ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 12px;
}
.pagination-container .previous a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
}

.pagination-container .previous a:hover {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.pagination-container .disabled {
  cursor: initial;
}

.pagination-container .disabled a {
  border: 1px solid transparent !important;
  background: transparent !important;
  cursor: initial;
}

.pagination-container .disabled svg path[stroke] {
  stroke: #c7c7c7;
}

.pagination-container .disabled a {
  cursor: initial;
}

.pagination-container .disabled svg {
  cursor: initial;
}
.pagination-container .next a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-container .next a:hover {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.pagination-container .page-item a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
  color: #7a7a7a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.pagination-container .page-item a:hover {
  background: #fdbe44;
  border: 1px solid #fdbe44;
  color: #ffffff;
}

.pagination-container .active a {
  background: #fdbe44;
  border: 1px solid #fdbe44;
  color: #ffffff;
}
